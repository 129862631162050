import React, { useEffect } from 'react';
import '../css/banner.css';
import { MitButton, MitCheckBox } from './ComponentUtils';
import { MitStorage } from 'src/managers/MitStorage';
import { Localization, SettingsManager } from '@viamap/viamap2-common';

export function MapitV4Banner() {
    let [checkBox, setCheckBox] = React.useState(false);
    let [remeberClosed, _setRememberClosed] = React.useState<"showBox" | Date | "redirect">(MitStorage.getValue("AutoRedirectToMapitV4") ?? "showBox");
    
    function setRememberClosed(choice: "showBox" | "hideBox" | "redirect") {
        let choiceVal = choice == "hideBox" ? new Date() : choice;
        _setRememberClosed(choiceVal);
        if (checkBox) {
            MitStorage.setValue("AutoRedirectToMapitV4", choiceVal);
        }
    }

    useEffect(() => {
        // Handles user that has been redirected to mapit4 and now wants to go back to mapit3
        if (window.location.search.toLowerCase().includes("backtomapit3")) {
            MitStorage.setValue("AutoRedirectToMapitV4", "showBox");
            return;
        }
        // Handles user that has been redirected to mapit4 and now might wants to go back to mapit3
        if (remeberClosed == "redirect") {
            window.open("https://mapit4.viamap.net?gotomapit4"+window.location.hash, "_self");   
        }
    }, [remeberClosed]);



    if (!(typeof remeberClosed == "string") && remeberClosed > timeAgo(SettingsManager.getSystemSetting("daysToRemindOfMapit4",7), "day")) {
        return null
    }
    if (!SettingsManager.getSystemSetting("mapitV4Banner", false)) {
        return null;
    }

    return (
        <>
        <div className='MapitV4BannerBackdrop'></div>
        <div className="MapitV4Banner">
            {Localization.getLanguage() === "da" ? 
            <>
            <h5>Opgrader til Mapit 4 Gratis</h5>
            <ul>
                <li>Nyt design</li>
                <li>Op til 40% hurtigere</li>
                <li>Optimeret til mobil & tablet</li>
            </ul>
            <p>Samt en lang række nye funktioner og forbedringer</p>
            </>
            :
            <>
            <h5>Upgrade to Mapit 4 for Free</h5>
            <ul>
                <li>New design</li>
                <li>Up to 40% faster</li>
                <li>Optimized for mobile & tablet</li>
            </ul>
            <p>As well as a wide range of new features and improvements</p>    
            </>}

            <div className='Buttons'>
                <MitCheckBox style={{marginRight:"auto"}} checked={checkBox} onChange={(e) => setCheckBox(e.target.checked)} variant={'normal'} >{Localization.getText("Remember choice")}</MitCheckBox>
                <MitButton variant={"normal"} style={{background:"#9d9d9d", fontSize:"13px"}} onClick={() => setRememberClosed("redirect")} >{Localization.getText("Go to Mapit 4")}</MitButton>
                <MitButton variant={"close"} style={{fontSize:"13px"}} onClick={() => setRememberClosed("hideBox")} >{Localization.getText("Close")}</MitButton>
            </div>
        </div>
        </>
    );
}

function timeAgo(count: number, unit: "minute" | "hour" | "day" | "week" | "month") {
    let date = new Date();
    switch (unit) {
        case "minute": date.setMinutes(date.getMinutes() - count); break;
        case "hour": date.setHours(date.getHours() - count); break;
        case "day": date.setDate(date.getDate() - count); break;
        case "week": date.setDate(date.getDate() - count * 7); break;
        case "month": date.setMonth(date.getMonth() - count); break;
    }
    return date;
}