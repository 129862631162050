
export class MitStorage {

  /**
   * Stores any type of value in key
   * @returns False if failed
   */
  static setValue<T>(key:string, value:T): boolean {
    try {
      window.localStorage.setItem(key, JSON.stringify({val : value}, this.replacer))
    } catch (error) {
      return false
    }
    return true
  }

  /**
   * Removes stored key / value
   */
  static clearValue(key:string):void {
    window.localStorage.removeItem(key)
  }

  /**
   * @returns value of given key as same type as when set
   */
  static getValue<T>(key:string):T | undefined {
    let obj = window.localStorage.getItem(key)
    if (obj == null) {
      return undefined
    }
    try {
      return JSON.parse(obj, this.reviver).val
    } catch {
      return undefined
    }
  }

  private static replacer(key, value) {
    return this[key] instanceof Date ? {_date: this[key].toISOString()} : value
  }

  private static reviver(key, value) {
    return value._date ? new Date(value._date) : value
  }
}

